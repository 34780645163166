import { useState } from 'react';

const useElementHeight = (className: string): number => {
  const [height, setHeight] = useState<number | null>(null);

  if (height != null) {
    return height;
  }

  const element = document.querySelector(`.${className}`);

  if (!element) {
    return 0;
  }

  const htmlElement = element as HTMLElement;

  const outerHeight = htmlElement.offsetHeight;

  setHeight(outerHeight);

  return outerHeight;
};

export default useElementHeight;
