import { useEffect, useState } from 'react';
import {
  ItemWithDetails,
  ItemWithAllDetails,
  AssetsCollection,
  Layout,
  Library
} from '../../constants/types';

function orderList(inputDict: AssetsCollection, sortBy: string[]) {
  const ordered: ItemWithAllDetails[] = [];
  sortBy.forEach(key => {
    if (key in inputDict) {
      const value = inputDict[key];
      ordered.push(value as ItemWithAllDetails);
    }
  });
  return ordered;
}

const useOrderedItems = (
  layout: Layout,
  library: Library,
  searchResultDict: AssetsCollection | null,
  searchResultList: string[] | null,
  isInfiniteScrollEnabled: boolean,
  isInfiniteScrollWebEnabled: boolean,
  numberOfItemsToDisplay: number
) => {
  const isRegularItemsResultAvailable = library.initialized && !library.isPhone;

  const [orderedItems, setOrderedItems] = useState<ItemWithDetails[]>();
  const [orderedItemsLoaded, isOrderedItemsLoaded] = useState<boolean>();

  const isItemsLoaded = layout.isSearchItemsLoaded && orderedItemsLoaded;

  const showShimmer = !isItemsLoaded && !layout.searchItemsError;

  const showNonInfiniteItems =
    isRegularItemsResultAvailable && (!isInfiniteScrollEnabled || !isInfiniteScrollWebEnabled);

  useEffect(() => {
    isOrderedItemsLoaded(false);
    setOrderedItems(undefined);

    if (searchResultList === null || !layout.isSearchItemsLoaded) {
      return;
    }

    let ordered: ItemWithAllDetails[] = [];

    if (searchResultList.length) {
      if (searchResultDict != null) {
        ordered = orderList(searchResultDict, searchResultList);
      } else {
        return;
      }
    }

    let limit = -1;
    if (isInfiniteScrollEnabled) {
      if (!isInfiniteScrollWebEnabled) {
        limit = numberOfItemsToDisplay;
      }
    } else if (showNonInfiniteItems) {
      limit = numberOfItemsToDisplay;
    }

    if (limit === -1) {
      setOrderedItems(ordered);
    } else {
      const limitedOrderedItems = ordered.slice(0, limit);
      setOrderedItems(limitedOrderedItems);
    }
    isOrderedItemsLoaded(true);
  }, [
    searchResultDict,
    searchResultList,
    isInfiniteScrollEnabled,
    isInfiniteScrollWebEnabled,
    numberOfItemsToDisplay,
    layout.isSearchItemsLoaded,
    showNonInfiniteItems
  ]);

  return {
    orderedItems,
    showNonInfiniteItems,
    showShimmer
  };
};

export default useOrderedItems;
