import React from 'react';
import { render } from 'react-dom';
import '../../../css/catalog/catalog.scss';
import CatalogPageContainer from './containers/CatalogPageContainer';

const ENTRY_ID = 'catalog-react-container';

function renderCatalogContainer(): void {
  const containerElement = document.getElementById(ENTRY_ID);
  if (containerElement) {
    render(<CatalogPageContainer />, containerElement);
  }
}

window.addEventListener('load', renderCatalogContainer);
