/* eslint-disable jsx-a11y/control-has-associated-label */
// For passing SystemFeedbackService
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// For checkbox not working with its label
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import * as Thumbnails from 'roblox-thumbnails';
import { numberFormat, escapeHtml } from 'core-utilities';
import { BatchBuyPriceContainer } from 'roblox-item-purchase';
import { TItemDetailRequestEntry, CurrentUser, TDetailEntry } from 'Roblox';
import { Button, ScrollBar } from 'react-style-guide';
import { eventStreamService, paymentFlowAnalyticsService } from 'core-roblox-utilities';
import useShoppingCart from '../hooks/useShoppingCart';
import { removeItemAction } from '../utils/actions';
import { TItemDetails, TCartDispatcher, TCartItem } from '../constants/types';
import { catalogTranslations, itemTranslations } from '../services/translationService';
import '../../../../css/shoppingCart/shoppingCart.scss';
import {
  isDomElementInShoppingCart,
  recalculateCartTotalFromSelectedItems,
  setCartState,
  fetchCartState,
  getItemLink,
  getItemPrice
} from '../utils/cartUtils';
import shoppingCartConstants from '../constants/shoppingCartConstants';

const { Thumbnail2d, DefaultThumbnailSize, ThumbnailTypes, ThumbnailFormat } = Thumbnails;
type TPurchaseDataResult = {
  itemData: {
    assetId: number;
    bundleId: number;
  };
  reason: string;
};
type TPurchaseEventItem = {
  itemType: string;
  itemId: number;
  resalePurchase: boolean;
};

function XGlyph() {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM6.46979 6.46967C6.76269 6.17678 7.23756 6.17678 7.53045 6.46967L11.0001 9.93934L14.4695 6.46992C14.7624 6.17703 15.2373 6.17703 15.5302 6.46992C15.8231 6.76282 15.8231 7.23769 15.5302 7.53058L12.0608 11L15.5302 14.4694C15.8231 14.7623 15.8231 15.2372 15.5302 15.5301C15.2373 15.823 14.7624 15.823 14.4695 15.5301L11.0001 12.0607L7.53045 15.5303C7.23756 15.8232 6.76269 15.8232 6.46979 15.5303C6.1769 15.2374 6.1769 14.7626 6.46979 14.4697L9.93946 11L6.46979 7.53033C6.1769 7.23744 6.1769 6.76256 6.46979 6.46967Z'
        fill='white'
      />
    </svg>
  );
}

function ItemPrice({ item, itemDetails }: { item: TCartItem; itemDetails: TItemDetails }) {
  const expectedPrice = getItemPrice(itemDetails);
  if (itemDetails.collectibleItemId !== undefined) {
    const isMarketPlaceEnabled = itemDetails.saleLocationType === 'ShopAndAllExperiences';
    const { hasResellers } = itemDetails;

    if (!isMarketPlaceEnabled && !hasResellers) {
      return (
        <div className='item-price'>
          <span className='price-text text-alert'>{itemTranslations.labelItemNotForSale()}</span>
        </div>
      );
    }
  }

  if (typeof expectedPrice === 'number' && expectedPrice > 0) {
    return (
      <div className='item-price'>
        <span className='icon-robux-16x16' />
        <span className='price-text text'>{numberFormat.getNumberFormat(expectedPrice)}</span>
      </div>
    );
  }

  if (typeof expectedPrice === 'number' && expectedPrice < 0) {
    return (
      <div className='item-price'>
        <span className='price-text text'>{itemDetails.priceStatus}</span>
      </div>
    );
  }

  if (typeof expectedPrice === 'number' && expectedPrice === 0) {
    return (
      <div className='item-price'>
        <span className='price-text text'>{itemTranslations.labelFree()}</span>
      </div>
    );
  }

  const itemRestrictions = itemDetails?.itemRestrictions || [];
  const isLimited = itemRestrictions.some(
    r => r === 'Limited' || r === 'LimitedUnique' || r === 'Collectible'
  );
  if (isLimited) {
    const limitedUnavailable =
      isLimited && !!itemDetails?.lowestPrice && typeof itemDetails?.lowestPrice !== 'number';
    if (limitedUnavailable) {
      return (
        <div className='item-price'>
          <span className='price-text text-alert'>{catalogTranslations.labelNoResellers()}</span>
        </div>
      );
    }
  } else if (typeof expectedPrice !== 'number') {
    return (
      <div className='item-price'>
        <span className='price-text text-alert'>{itemTranslations.labelItemNotForSale()}</span>
      </div>
    );
  }

  return (
    <div className='item-price'>
      <span className='price-text' />
    </div>
  );
}

function ShoppingCartModalItem({
  item,
  itemDetails,
  dispatch,
  onCheckboxClicked,
  selectedItemsRecord
}: {
  item: TCartItem;
  itemDetails: TItemDetails;
  dispatch: TCartDispatcher;
  onCheckboxClicked: (item: TCartItem, overrideValue?: boolean) => void;
  selectedItemsRecord: Record<string, boolean>;
}) {
  const itemName = itemDetails?.name || item.itemName;
  const creatorName = itemDetails?.creatorName;
  return (
    <div className='cart-item-container'>
      <div className='cart-item'>
        <div
          className='thumbnail-container'
          onClick={() => onCheckboxClicked(item)}
          aria-hidden='true'>
          <Thumbnail2d
            type={
              item.itemType?.toLowerCase() === 'bundle'
                ? ThumbnailTypes.bundleThumbnail
                : ThumbnailTypes.assetThumbnail
            }
            size={DefaultThumbnailSize}
            targetId={item.itemId}
            containerClass='cart-item-thumb'
            format={ThumbnailFormat.webp}
            altName={(itemName || 'Cart Item') + (creatorName ? ` by ${creatorName}` : '')}
          />
        </div>

        <div className='checkbox purchase-checkbox-container'>
          <input
            className='input-checkbox'
            id={`checkbox-${item.itemId}`}
            type='checkbox'
            checked={selectedItemsRecord[`${item.itemType.toLowerCase()}${item.itemId}`]}
            onChange={() => {
              onCheckboxClicked(item);
            }}
            disabled={false}
          />
          <label htmlFor={`checkbox-${item.itemId}`} />
        </div>
        <div className='item-details-container'>
          <a
            href={getItemLink(item.itemId, itemName, item.itemType)}
            target='_self'
            className='item-name'>
            {itemName}
          </a>
          <ItemPrice item={item} itemDetails={itemDetails} />
        </div>
      </div>
      <div className='rm-item-btn-container icon-actions-clear-sm'>
        <button
          type='button'
          onClick={() => {
            dispatch(removeItemAction(item, true)).catch(() => {
              console.error('Failed to remove item');
            });
          }}
        />
      </div>
    </div>
  );
}

function ShoppingCartModalFooter({
  totalPrice,
  remainingBalance,
  items,
  selectedItemsList,
  systemFeedbackService,
  totalCartValue,
  itemDetails,
  dispatch
}: {
  totalPrice: number;
  remainingBalance: number;
  items: TCartItem[];
  selectedItemsList: TCartItem[];
  systemFeedbackService: any;
  totalCartValue: number;
  itemDetails: Record<string, TDetailEntry>;
  dispatch: TCartDispatcher;
}) {
  const checkIfItemShouldPurchaseFromReseller = (itemDetail: TDetailEntry) => {
    if (
      itemDetail.itemRestrictions.includes('LimitedUnique') ||
      itemDetail.itemRestrictions.includes('Limited')
    ) {
      return true;
    }
    if (
      itemDetail.collectibleItemId !== undefined &&
      itemDetail.collectibleItemDetails !== undefined &&
      itemDetail.itemRestrictions.includes('Collectible')
    ) {
      if (
        itemDetail.collectibleItemDetails.unitsAvailableForConsumption !== undefined &&
        itemDetail.collectibleItemDetails.unitsAvailableForConsumption > 0
      ) {
        if (
          (itemDetail.collectibleItemDetails.lowestResalePrice !== undefined &&
            itemDetail.collectibleItemDetails.lowestResalePrice >
              itemDetail.collectibleItemDetails?.price) ||
          itemDetail.collectibleItemDetails.saleLocationType === 'ExperiencesDevApiOnly'
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };
  const onTransactionComplete = useCallback(
    (
      results: Array<Record<string, TPurchaseDataResult>>,
      attemptedPurchaseCount: number,
      attemptedPurchaseValue: number
    ) => {
      const params = {
        totalNumberOfItemsInCart: items.length,
        totalValueOfItemsInCart: totalCartValue,
        totalNumberOfSelectedItemsInCheckout: attemptedPurchaseCount,
        totalValueOfSelectedItemsInCheckout: attemptedPurchaseValue,
        purchaseData: JSON.stringify(results),
        userId: CurrentUser.userId
      };
      eventStreamService.sendEvent(
        {
          name: 'shoppingCartPurchaseResult',
          type: 'shoppingCartPurchaseResult',
          context: 'shoppingCart'
        },
        params
      );
      paymentFlowAnalyticsService.sendUserPurchaseFlowEvent(
        paymentFlowAnalyticsService.ENUM_TRIGGERING_CONTEXT.WEB_CATALOG_CART_ROBUX_UPSELL,
        true,
        paymentFlowAnalyticsService.ENUM_VIEW_NAME.ROBUX_UPSELL,
        paymentFlowAnalyticsService.ENUM_PURCHASE_EVENT_TYPE.USER_INPUT,
        paymentFlowAnalyticsService.ENUM_VIEW_MESSAGE.BUY_ROBUX
      );

      let totalTransactionValue = 0;
      const purchasedItems = [] as Array<TPurchaseEventItem>;
      results.forEach(result => {
        if (result.data.reason === 'Success') {
          let itemDetail: TDetailEntry;
          if (result.data.itemData.assetId !== undefined) {
            itemDetail = itemDetails[result.data.itemData.assetId];
            if (checkIfItemShouldPurchaseFromReseller(itemDetail)) {
              if (
                itemDetail.collectibleItemId !== undefined &&
                itemDetail.collectibleItemDetails.lowestResalePrice !== undefined
              ) {
                totalTransactionValue += itemDetail.collectibleItemDetails.lowestResalePrice;
              } else if (itemDetail.lowestPrice !== undefined) {
                totalTransactionValue += itemDetail.lowestPrice;
              }
            } else if (itemDetail !== undefined && itemDetail.price) {
              totalTransactionValue += itemDetail.price;
            }
            purchasedItems.push({
              itemType: itemDetail.itemType,
              itemId: itemDetail.id,
              resalePurchase: checkIfItemShouldPurchaseFromReseller(itemDetail)
            });

            dispatch(
              removeItemAction({ itemId: result.data.itemData.assetId, itemType: 'Asset' })
            ).catch(() => {
              console.error('Failed to remove item');
            });
          } else if (result.data.itemData.bundleId !== undefined) {
            itemDetail = itemDetails[result.data.itemData.bundleId];
            if (checkIfItemShouldPurchaseFromReseller(itemDetail)) {
              if (
                itemDetail.collectibleItemId !== undefined &&
                itemDetail.collectibleItemDetails.lowestResalePrice !== undefined
              ) {
                totalTransactionValue += itemDetail.collectibleItemDetails.lowestResalePrice;
              } else if (itemDetail.lowestPrice !== undefined) {
                totalTransactionValue += itemDetail.lowestPrice;
              }
            } else if (itemDetail !== undefined && itemDetail.price) {
              totalTransactionValue += itemDetail.price;
            }
            purchasedItems.push({
              itemType: itemDetail.itemType,
              itemId: itemDetail.id,
              resalePurchase: checkIfItemShouldPurchaseFromReseller(itemDetail)
            });

            dispatch(
              removeItemAction({ itemId: result.data.itemData.bundleId, itemType: 'Bundle' })
            ).catch(() => {
              console.error('Failed to remove item');
            });
          }
        }
      });

      const eventParams = {
        totalTransactionValue,
        transactionItems: JSON.stringify(purchasedItems),
        purchaseType: 'shopping-cart',
        userId: CurrentUser.userId
      };
      eventStreamService.sendEvent(
        {
          name: 'marketplaceWebPurchaseSuccess',
          type: 'marketplaceWebPurchaseSuccess',
          context: 'marketplaceWebPurchase'
        },
        eventParams
      );
    },
    []
  );
  const parseItems = () => {
    const selectedItems = [] as TItemDetailRequestEntry[];
    selectedItemsList.forEach(item => {
      selectedItems.push({ id: item.itemId, itemType: item.itemType });
    });
    return selectedItems;
  };

  const onTooManyItemsButtonClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    systemFeedbackService.warning(
      catalogTranslations.messagePurchaseLimit({
        purchaseLimit: `${shoppingCartConstants.maxSelectedItems}`
      }),
      0,
      2000
    );
  };

  return (
    <div className='shopping-cart-footer'>
      <div className='cart-total-section-container'>
        <div className='total-label'>
          {catalogTranslations.labelTotalItems({ itemCount: `${selectedItemsList.length}` })}
        </div>
        <div className='total-price-container'>
          <span className='icon-robux-16x16' />
          <span className='price-text'>{totalPrice.toLocaleString()}</span>
        </div>
      </div>
      {selectedItemsList.length > shoppingCartConstants.maxSelectedItems && (
        <Button
          className='action-button batch-buy-purchase-button'
          variant={Button.variants.growth}
          size={Button.sizes.large}
          onClick={onTooManyItemsButtonClick}>
          {catalogTranslations.actionBuy()}
        </Button>
      )}
      {selectedItemsList.length <= shoppingCartConstants.maxSelectedItems && (
        <BatchBuyPriceContainer
          items={parseItems()}
          onTransactionComplete={(results: Array<Record<string, TPurchaseDataResult>>) => {
            onTransactionComplete(results, selectedItemsList.length, totalPrice);
          }}
          // using any as a type for systemFeedbackService because we don't have an exported type for ts
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          systemFeedbackService={systemFeedbackService}
        />
      )}

      <div className='balance-disclaimer-container'>
        {remainingBalance >= 0 ? (
          <div
            className='balance-disclaimer-text'
            dangerouslySetInnerHTML={{
              __html: catalogTranslations.messageRemainingBalance({
                remainingBalance: `<span class='icon-robux-16x16'></span><span class='text-robux'>${escapeHtml()(
                  remainingBalance.toLocaleString()
                )}</span>`
              })
            }}
          />
        ) : (
          <div className='balance-disclaimer-text'>
            {catalogTranslations.messageInsufficientFundsForTransaction()}
          </div>
        )}
      </div>
    </div>
  );
}

function ShoppingCartModal(props: {
  setIsCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  systemFeedbackService: any;
}): JSX.Element {
  const { cart, dispatch } = useShoppingCart();
  const { items, currentUserBalance, itemDetails } = cart;

  const currentBalance = Math.max(currentUserBalance ?? currentUserBalance ?? 0, 0);
  const ref = useRef<HTMLDivElement>(null);

  const [modalRect, setModalRect] = useState({
    windowHeight: window.innerHeight,
    width: null,
    height: null,
    top: null,
    left: null
  } as { windowHeight: number; width: number | null; height: number | null; top: number | null; left: number | null });
  const [totalPrice, setTotalPrice] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [selectedItemsList, setSelectedItemsList] = useState([] as TCartItem[]);
  const [selectedItemsObject, setSelectedItemsObject] = useState({} as Record<string, boolean>);

  const updateTotalPrice = (itemList: TCartItem[]) => {
    const totalSelectedPrice = recalculateCartTotalFromSelectedItems(cart, itemList);
    setTotalPrice(totalSelectedPrice);
    setRemainingBalance(currentBalance - totalPrice);
  };
  useEffect(() => {
    const updatedCart = fetchCartState();
    setSelectedItemsObject(updatedCart.selectedItems);
  }, []);

  useEffect(() => {
    const updatedCart = fetchCartState();
    setSelectedItemsObject(updatedCart.selectedItems);
  }, [items]);

  useEffect(() => {
    const updatedSelectedItemsList = [] as TCartItem[];
    items.forEach(item => {
      if (selectedItemsObject[`${item.itemType.toLowerCase()}${item.itemId}`]) {
        updatedSelectedItemsList.push(item);
      }
    });
    updateTotalPrice(updatedSelectedItemsList);
    setSelectedItemsList(updatedSelectedItemsList);
  }, [selectedItemsObject]);

  // close modal if clicking outside of modal
  const handleWindowClick = (evt: MouseEvent) => {
    const target = evt?.target;
    const modalContainer = ref?.current;

    if (target instanceof Element) {
      if (modalContainer && modalContainer.contains(target)) return;
      if (isDomElementInShoppingCart(target)) {
        return;
      }
      props?.setIsCartOpen?.(false);
    }
  };

  // save a ref to the modal for calculating the X, Y, width, height positioning
  const cartModalRef = useCallback(node => {
    if (!node || !(node instanceof HTMLElement)) return;

    const calculateContainerInnerRects = (n: HTMLElement) => {
      const clientRect = n?.getBoundingClientRect?.();
      if (clientRect && clientRect?.width && clientRect?.height) {
        const newContainerDimensions = {
          windowHeight: window.innerHeight,
          width: clientRect?.width,
          height: clientRect?.height,
          top: clientRect?.top ?? clientRect?.y,
          left: clientRect?.left ?? clientRect?.x
        };
        setModalRect(newContainerDimensions);
      }
    };

    calculateContainerInnerRects(node);

    const intObs = new IntersectionObserver(
      entries => {
        const entry = entries?.[0];
        if (!entry?.target) return;
        calculateContainerInnerRects(node);
      },
      { root: null, rootMargin: '0px', threshold: [0.9, 0.99, 1.0] }
    );

    const resizeObs = new ResizeObserver(entries => {
      calculateContainerInnerRects(node);
    });
    intObs.observe(node);
    resizeObs.observe(document.body, { box: 'border-box' });
  }, []);

  const viewportEdgePadding = 20;

  // generates the modal height
  const modalHeight = useMemo(() => {
    const h = modalRect.height;
    const t = modalRect.top;
    if (typeof h !== 'number' || typeof t !== 'number') return 600;

    const bottomOffset = h + t;
    if (bottomOffset > modalRect.windowHeight) {
      const delta = bottomOffset - modalRect.windowHeight;
      return Math.max(460, h - delta - viewportEdgePadding);
    }

    if (bottomOffset < modalRect.windowHeight - viewportEdgePadding) {
      const expectedHeight = modalRect.windowHeight - viewportEdgePadding - t;
      if (expectedHeight >= 460) {
        return Math.min(600, expectedHeight);
      }
    }

    return modalRect.height ? modalRect.height : 0;
  }, [modalRect.windowHeight, modalRect.height, modalRect.top]);

  const onCheckboxClicked = (item: TCartItem, overrideValue?: boolean) => {
    const selectedItems = selectedItemsObject;
    const selectedItemKey = `${item.itemType.toLowerCase()}${item.itemId}`;
    let selectedValue = false;
    if (overrideValue === undefined) {
      if (selectedItems[selectedItemKey] !== undefined) {
        selectedValue = !selectedItems[selectedItemKey];
      } else {
        selectedValue = true;
      }
    } else {
      selectedValue = overrideValue;
    }
    if (selectedValue && selectedItemsList.length >= shoppingCartConstants.maxSelectedItems) {
      selectedValue = false;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      props.systemFeedbackService.warning(
        catalogTranslations.messagePurchaseLimit({
          purchaseLimit: `${shoppingCartConstants.maxSelectedItems}`
        }),
        0,
        2000
      );
    }
    selectedItems[selectedItemKey] = selectedValue;
    setSelectedItemsObject({ ...selectedItems });
    setCartState({ ...cart, selectedItems: selectedItemsObject });
  };

  //
  useEffect(() => {
    window.addEventListener('click', handleWindowClick, true);
    return () => {
      window.removeEventListener('click', handleWindowClick, true);
    };
  }, []);
  const itemCountFormatted = `(${items.length})`;
  return (
    <div ref={ref} className='shopping-cart-modal-container'>
      <div
        ref={cartModalRef}
        className='shopping-cart-modal'
        style={{ height: `${modalHeight}px` }}>
        <div className='shopping-cart-title-container'>
          <h2 className='shopping-cart-title'>
            <span className='shopping-cart-title-text'>
              {catalogTranslations.labelShoppingCart()}
            </span>{' '}
            <span className='shopping-cart-title-item-count'>{itemCountFormatted}</span>
          </h2>
        </div>
        <ScrollBar className='rbx-scrollbar'>
          <div className='shopping-cart-items-list'>
            {items.map(item => (
              <ShoppingCartModalItem
                key={item.itemId}
                item={item}
                itemDetails={itemDetails[item.itemId]}
                dispatch={dispatch}
                onCheckboxClicked={onCheckboxClicked}
                selectedItemsRecord={selectedItemsObject}
              />
            ))}
          </div>
        </ScrollBar>
        <ShoppingCartModalFooter
          totalPrice={totalPrice}
          totalCartValue={recalculateCartTotalFromSelectedItems(cart, cart.items)}
          remainingBalance={remainingBalance}
          items={items}
          selectedItemsList={selectedItemsList}
          systemFeedbackService={props.systemFeedbackService}
          itemDetails={itemDetails}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
}

ShoppingCartModal.propTypes = {};

export default ShoppingCartModal;
