import { RobloxTranslationResource, TranslationResourceProvider } from 'Roblox';
import { numberFormat } from 'core-utilities';
import {
  translationConfig,
  featureItemTranslationConfig,
  itemModelTranslationConfig
} from '../translation.config';

const resourceProvider = new TranslationResourceProvider();

// TODO: for any translations in Feature.Catalog that should have been used,
//       but weren't because they were Lua only, activate them for web.

export type TTranslationNamespace = 'featureCatalog' | 'featureItem' | 'featureItemModel';

export const translationResources: Record<TTranslationNamespace, RobloxTranslationResource> = {
  featureCatalog: resourceProvider.getTranslationResource(translationConfig.feature),
  featureItem: resourceProvider.getTranslationResource(featureItemTranslationConfig.feature),
  featureItemModel: resourceProvider.getTranslationResource(itemModelTranslationConfig.feature)
};

export const catalogTranslations = {
  actionSeeMoreReseller: (): string => {
    return translationResources.featureCatalog.get('Action.SeeMoreReseller', {
      linkStart: '<a href="#resellers" class="text-link">',
      resellerLink: translationResources.featureCatalog.get('Heading.Resellers', {}),
      linkEnd: '</a>'
    });
  },
  actionBuy: (): string => {
    return translationResources.featureCatalog.get('Action.Buy', {});
  },
  labelCategoryType: (): string => {
    return translationResources.featureCatalog.get('Label.CategoryType', {});
  },
  labelTags: (): string => {
    return translationResources.featureCatalog.get('Label.Tags', {});
  },
  labelSalesType: (): string => {
    return translationResources.featureCatalog.get('Label.Filter.SalesType', {});
  },
  labelCollectibles: (): string => {
    return translationResources.featureCatalog.get('LabelCollectibles', {});
  },
  messageCollectiblesInfo: (): string => {
    return translationResources.featureCatalog.get('Message.CollectiblesInfo', {});
  },
  labelNewCollectibles: (): string => {
    return translationResources.featureCatalog.get('Label.NewCollectibles', {});
  },
  labelByCreatorLink: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Label.ByCreatorLink', params);
  },
  labelTradable: (): string => {
    return translationResources.featureCatalog.get('Label.Tradable', {});
  },
  labelDescription: (): string => {
    return translationResources.featureCatalog.get('Label.Description', {});
  },
  labelNotAvailable: (): string => {
    return translationResources.featureCatalog.get('Label.NotAvailable', {});
  },
  labelNoResellers: (): string => {
    return translationResources.featureCatalog.get('LabelNoResellers', {});
  },
  actionAddToCart: (): string => {
    return translationResources.featureCatalog.get('Action.AddToCart', {});
  },
  actionRemoveFromCart: (): string => {
    return translationResources.featureCatalog.get('Action.RemoveFromCart', {});
  },
  headingItemAddedToCart: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Heading.ItemAddedToCart', params);
  },
  headingItemRemovedFromCart: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Heading.ItemRemovedFromCart', params);
  },
  labelTotalUnformatted: (): string => {
    return translationResources.featureCatalog.get('Label.TotalUnformatted', {});
  },
  messageRemainingBalance: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Message.RemainingBalance', params);
  },
  messageInsufficientFundsForTransaction: (): string => {
    return translationResources.featureCatalog.get('Message.InsufficientFundsForTransaction', {});
  },
  labelShoppingCart: (): string => {
    return translationResources.featureCatalog.get('Label.ShoppingCart', {});
  },
  labelTotalItems: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Label.TotalItems', params);
  },
  messagePurchaseLimit: (params: Record<string, string>): string => {
    return translationResources.featureCatalog.get('Message.PurchaseLimit', params);
  }
};

export const itemTranslations = {
  labelShowLess: (): string => {
    return translationResources.featureItem.get('Label.ShowLess', {});
  },
  labelReadMore: (): string => {
    return translationResources.featureItem.get('Label.ReadMore', {});
  },
  labelFree: (): string => {
    return translationResources.featureItem.get('Label.Free', {});
  },
  actionGetPremium: (): string => {
    return translationResources.featureItem.get('Action.GetPremium', {});
  },
  actionBuy: (): string => {
    return translationResources.featureItem.get('Action.Buy', {});
  },
  labelPremiumDiscountSavings: (discountPercentage: number, originalPrice: number): string => {
    const robuxPrice = `<span class="discount-savings-original-price"><span class="icon-robux-16x16"></span>${numberFormat.getNumberFormat(
      originalPrice
    )}</span>`;
    return translationResources.featureItem.get('Label.PremiumDiscountSavings', {
      discountPercentage: numberFormat.getNumberFormat(discountPercentage),
      originalPrice: robuxPrice
    });
  },
  labelPremiumDiscountOpportunityPrompt: (premiumPrice: number): string => {
    const robuxPrice = `<span class="discount-savings-original-price"><span class="icon-robux-16x16"></span>${numberFormat.getNumberFormat(
      premiumPrice
    )}</span>`;
    return translationResources.featureItem.get('Label.PremiumDiscountOpportunityPrompt', {
      premiumDiscountedPrice: robuxPrice
    });
  },
  labelBestPrice: (): string => {
    return translationResources.featureItem.get('Label.BestPrice', {});
  },
  labelPrice: (): string => {
    return translationResources.featureItem.get('Label.Price', {});
  },
  actionYes: (): string => {
    return translationResources.featureItem.get('Action.Yes', {});
  },
  actionNo: (): string => {
    return translationResources.featureItem.get('Action.No', {});
  },
  actionInventory: (): string => {
    return translationResources.featureItem.get('Action.Inventory', {});
  },
  labelItemNotForSale: (): string => {
    return translationResources.featureItem.get('Label.ItemNotForSale', {});
  }
};

export const itemModelTranslations = {
  labelLimitedQuantity: (
    unitsAvailableForConsumption: number,
    totalQuantity?: number | null
  ): string => {
    const unitsForConsumption = numberFormat.getNumberFormat(unitsAvailableForConsumption);
    const total =
      typeof totalQuantity === 'number' ? numberFormat.getNumberFormat(totalQuantity) : null;
    return translationResources.featureItemModel.get('Label.LimitedQuantity', {
      amount: total ? `${unitsForConsumption}/${total}` : unitsForConsumption
    });
  }
};

export default translationResources;
