import { AxiosPromise, httpService } from 'core-utilities';
import catalogConstants from '../constants/catalogConstants';

export type VngBuyRobuxPolicyResponse = {
  shouldShowVng: boolean;
};

class UniversalAppConfigurationService {
  static getVngBuyRobuxPolicy(): AxiosPromise<VngBuyRobuxPolicyResponse> {
    return httpService.get<VngBuyRobuxPolicyResponse>(
      catalogConstants.endpoints.getVngBuyRobuxBehavior
    );
  }
}

export default UniversalAppConfigurationService;
